#downloads {
  h3 {
    margin-bottom: 40px;
  }

  .card {
    margin-bottom: 40px;

    &.app {
      padding: 20px;
      position: relative;
      text-align: center;

      .appStore,
      .googlePlay {
        position: absolute;
        right: 8%;
      }

      .appStore {
        top: 55%;
      }

      .googlePlay {
        top: 70%;
      }
    }
  }

  .meta-box {
    padding: 40px 20px 0 20px;
    background-color: $light-gray;
    border-radius: 0 8px 8px 8px;
    max-width: 1200px;
    margin: 60px auto;

    .box {
      padding-bottom: 50px;

      ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, 169px);
        column-gap: 2%;
        row-gap: 20px;

        /deep/ li {
          vertical-align: middle;
          display: inline-block;
          text-align: center;

          .title {
            font-size: 16px;
            color: $primary;
            margin-bottom: 20px;
          }

          .clickable {
            padding-top: 20%;
            border-color: $black;
            border-radius: 10px;
            height: 173px;
            background-color: $white;
            border-style: solid;
            border-width: $border-width;
            border-color: $border-color;
            border-radius: 6px;
            position: relative;

            .picture {
              width: 75px;
              height: 75px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: 0 0;
              margin: 0 auto;

              &.windows {
                background-image: url(~@/assets/images/download/windows.png);
              }

              &.mac {
                background-image: url(~@/assets/images/download/masOS.png);
              }

              &.linux {
                background-image: url(~@/assets/images/download/linux.png);
              }

              &.iphone {
                background-image: url(~@/assets/images/download/iphone.png);
              }

              &.android {
                background-image: url(~@/assets/images/download/android.png);
              }

              &.webtrader {
                background-image: url(~@/assets/images/download/webTrader.png);
              }
            }

            .download-btn {
              position: absolute;
              bottom: 10px;
              @include rtl-sass-prop(right, left, 10px);
              @include rtl-sass-prop(left, right, unset);
              width: 33px;
              height: 33px;
              background-color: #b8cdea;
              border-radius: 100px;
              background-image: url(~@/assets/images/download/dlBtn.png);
              background-repeat: no-repeat;
              background-position: 50% 50%;
            }

            &:hover {
              background-color: $primary;
              box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;

              .picture {
                background-position: -75px 0;
              }

              .download-btn {
                background-color: unset;
              }
            }
          }
        }
      }
    }
  }
}

.tab-demo li {
  top: -100px;
  @include rtl-sass-prop(left, right, -20px);
  @include rtl-sass-prop(right, left, unset);

  &:nth-child(2) {
    @include rtl-sass-prop(left, right, 280px);
    @include rtl-sass-prop(right, left, unset);
  }

  &.active {
    top: -142px;
  }
}

@media (max-width: 1400px) {
  .tab-demo {
    li {
      &:nth-child(2) {
        @include rtl-sass-prop(left, right, 122px);
        @include rtl-sass-prop(right, left, unset);
      }
    }
  }
}

@media (max-width: 1024px) {
  #downloads {
    .card.app {
      .appStore,
      .googlePlay {
        width: 100px;
      }
    }
    .meta-box {
      margin: 109px auto 20px;

      .box ul {
        grid-template-columns: repeat(auto-fill, calc((100% - 4%) / 3));
      }
    }
  }

  .tab-demo {
    li {
      width: 165px;

      &:nth-child(2) {
        @include rtl-sass-prop(left, right, 150px);
        @include rtl-sass-prop(right, left, unset);
      }
    }
  }
}

@media (max-width: 767px) {
  #downloads {
    .card.app {
      .appImg {
        content: url(~@/assets/images/download/appMobile.png);
      }
      .appStore {
        right: 6%;
        top: 28%;
      }

      .googlePlay {
        right: 6%;
        top: 36%;
      }
    }
    .meta-box {
      margin: 0 auto 20px;

      .box ul {
        grid-template-columns: repeat(auto-fill, calc((100% - 2%) / 2));
      }
    }
  }

  .tab-demo {
    li {
      top: -88px;
      @include rtl-sass-prop(left, right, -40px !important);
      @include rtl-sass-prop(right, left, unset);
      width: 137px;

      &:nth-child(2) {
        @include rtl-sass-prop(left, right, 100px !important);
        @include rtl-sass-prop(right, left, unset);
      }

      &.active {
        top: -105px;
      }
    }
  }
}
